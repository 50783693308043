<template>
  <v-col :cols="cols">
    <v-text-field
      class="labelStyle"
      :label="label"
      :placeholder="placeholder"
      height="30"
      dense
      outlined
      v-model="inputVal"
      :disabled="disabled"
      :prepend-inner-icon="innerIcon"
      :type="type"
      :error-messages="error"
      :rules="rules === 'true' || rules ? Empty : none"
      :hint="hint"
    ></v-text-field>
  </v-col>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    placeholder: {
      type: String,
    },
    model: {
      type: [String, Number, Array, Boolean],
    },
    reandonly: {
      type: String,
    },
    icon: {
      type: String,
    },
    type: {
      type: String,
    },
    cols: {
      type: String,
    },
    rules: {
      type: String,
    },
    innerIcon: {
      type: String,
    },
    error: {
      type: String,
    },
    hint: {
      type: String,
    },
  },
  data() {
    return {
      Empty: [(v) => !!v || "Campo obrigatório"],
      none: [],
    };
  },
  computed: {
    inputVal: {
      get() {
        return this.model;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/TextField.css";
</style>
